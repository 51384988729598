import { FC, PropsWithChildren } from 'react'
import { Flex, GridItem, GridProps } from '@chakra-ui/react'

import { AppGrid } from '~/templates/AppGrid'

// Component for the split blue/white background.
export const BackgroundBox: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    flex={1}
    overflow={{ lg: 'auto' }}
    flexDir="column"
    h="inherit"
    minH="$100vh"
    bgGradient={{
      // We use 41.6667% because at the lg size, the grid column of the login area
      // is 7/12, so the remaining space is 5/12 = 0.416666...
      lg: 'linear(to-r, slate.900 calc(41.6667% - 4px), white 0)',
    }}
  >
    {children}
  </Flex>
)

// Component that controls the various grid areas according to responsive breakpoints.
export const BaseGridLayout = (props: GridProps) => (
  <AppGrid templateRows={{ md: 'auto 1fr auto', lg: '1fr auto' }} {...props} />
)

// Grid area styling for the login form.
export const LoginGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    id="login-grid-area"
    gridColumn={{ base: '1 / 13', lg: '7 / 12' }}
    pb={{ lg: '3.5rem', base: '0' }}
    pt={{ lg: '3.5rem', base: '2rem' }}
    display="flex"
    alignItems={{ base: 'initial', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

// Grid area styling for the left sidebar that only displays on tablet and desktop breakpoints.
export const SidebarGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    id="sidebar-grid-area"
    display={{ lg: 'flex', base: 'none' }}
    bg={{ lg: 'slate.900', base: 'standard.white' }}
    gridColumn={{ base: '1 / 13', lg: '1 / 6' }}
    h={{ md: '18.5rem', lg: 'auto' }}
    pt={{ base: '1rem', lg: '3rem' }}
    pb={{ lg: '3rem' }}
    pr={{ lg: '1.5rem' }}
    flexDir="column"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </GridItem>
)
