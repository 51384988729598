import { Container } from '@chakra-ui/react'
import { Spinner } from '@opengovsg/design-system-react'
import { useRouter } from 'next/router'
import { trpc } from '~/utils/trpc'

export const SgidCallbackLoading = () => {
  return (
    <Container
      display="flex"
      h="$100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner color="interaction.main.default" fontSize="2rem" />
    </Container>
  )
}

/**
 * This component is responsible for handling the callback from the SGID login.
 */
export const SgidCallback = ({
  endpoint = 'callback',
}: {
  endpoint?: 'callback'
}) => {
  const router = useRouter()
  const {
    query: { code, state },
  } = router

  trpc.auth.sgid[endpoint].useSuspenseQuery(
    {
      code: String(code),
      state: String(state),
    },
    {
      retry: false,
      onSuccess: ({ redirectUrl }) => {
        router.replace(redirectUrl)
      },
    },
  )

  return <SgidCallbackLoading />
}
